import React from 'react';
import {
  Block,
  Content,
  TextSide,
  TitleContainer,
  Title,
  Container,
} from './StyledElements';
import LegalTxt from '../../Blocks/LegalTxt';
import { localize } from '../../components/language/config';

const LegalContainer = function () {
  return (
    <Block>
      <Content>
        <TextSide>
          <TitleContainer>
            <Title>{localize('Legal notice')}</Title>
          </TitleContainer>
        </TextSide>
        <Container>
          <LegalTxt
            titleLegal={localize('Editor')}
            infoLegal={localize('The site https://www.evolt.io is published by Evolution SAS, registered with the Trade and Companies Register of Montpellier under the number 81104937800011, whose head office is located at 418 Rue du Mas de Verchant, 34000 Montpellier.')}
          />
          <LegalTxt
            titleLegal={localize('Hosting')}
            infoLegal={localize('The site https://www.evolt.io is hosted by the company company OVH – SAS with a capital of 10 000 000 € – RCS Roubaix – Tourcoing 424 761 419 00045 – APE code 6202A VAT no: FR 22 424 761 419 – Registered office: 2 rue Kellermann – 59100 Roubaix – France.')}
          />
          <LegalTxt
            titleLegal={localize('Crédits')}
            infoLegal={localize('Visuals: Unsplash and évolt Illustrations: évolt')}
          />
          <LegalTxt
            titleLegal={localize('Relevance of contents')}
            infoLegal={localize('evolt.io aims to present you its software solutions and its expertise especially in the field of innovation and design. If you notice the presence of contents that are inaccurate or likely to infringe the rights of a third party or that you believe violate a regulatory or legislative provision, please let us know by writing to legal@evolt.io')}
          />
          <LegalTxt
            titleLegal={localize('Website writing')}
            infoLegal={localize('Director of the publication: Jonathan Scanzi – jonathan.scanzi@evolt.io. Responsible for writing the Site : Jonathan Scanzi. Contact : jonathan.scanzi@evolt.io')}
          />
          <LegalTxt
            titleLegal={localize('Respect for intellectual property')}
            infoLegal={localize('All brands, photographs, texts, comments, illustrations, animated or non-animated images, video sequences, sounds, as well as all the computer applications that could be used to make the Website work and more generally all the elements reproduced or used on the Site are protected by the laws in force for intellectual property. They are the full and entire property of the Publisher or its partners, unless particular mentions. Any reproduction, representation, use or adaptation, in any form whatsoever, of all or part of these elements, including computer applications, without the prior written consent of the Publisher, are strictly prohibited. The fact that the Publisher does not initiate proceedings upon becoming aware of these unauthorized uses does not constitute acceptance of such uses and waiver of prosecution. Only the use for private use in a family circle is allowed and any other use is constitutive of counterfeit and / or infringement of neighboring rights, sanctioned by the Code of Intellectual Property. The recovery of all or part of this content requires the prior permission of the Publisher or the owner of the rights on this content.')}
          />
          <LegalTxt
            titleLegal={localize('Hypertext links')}
            infoLegal={localize('The Site may contain hypertext links giving access to other websites edited and managed by third parties and not by the Publisher. The Publisher can not be held responsible directly or indirectly in the event that said third party sites do not comply with the legal provisions. The creation of hypertext links to the Site can only be done with the prior written authorization of the Publisher.')}
          />
          <LegalTxt
            titleLegal={localize('Collect of data')}
            infoLegal={localize('The collection of personal data is carried out in compliance with the provisions of REGULATION (EU) 2016/679 OF THE EUROPEAN PARLIAMENT AND OF THE COUNCIL of 27 April 2016 on the protection of individuals with regard to the processing of personal data. and the free movement of this data (RGPD), EVOLT ** using the services of www.axeptio.eu ** to collect your consent to the necessary treatments. Consent may be withdrawn at any time as easily as it has been given in accordance with the requirements of the GDPR.')}
          />
          <LegalTxt
            titleLegal={localize('Uses are as follows :')}
            infoLegal={localize('Management of the operation and optimization of the website Verification and authentication of the data transmitted by the USER Implementation of a user assistance. Prevention and detection of malware fraud and management of security incidents. GManagement of any disputes with users. Sending commercial and advertising information according to the preferences of the user. EVOLT makes every effort to protect personal data against tampering, destruction and unauthorized access. However, the Internet is not a completely secure environment and the website can not guarantee the total security of information storage transmission.')}
          />
          <LegalTxt
            titleLegal={localize('According to the GDPR, users have the following rights :')}
            infoLegal={localize('Update or delete the data that concerns them (via the settings of the user’s account). Delete their account. However, information shared with other users such as forum posts may remain visible to the public on the WEB SITE even after the account is deleted. Exercise their right of access to know their personal data. As part of such a request we will ask you to provide proof of your identity to verify its accuracy. Request the update of the information concerning them. Request the deletion of their personal data. To do this, send an e-mail to legal@evolt.io')}
          />
          <LegalTxt
            titleLegal={localize('Cookies')}
            infoLegal={localize('The site can automatically collect standard information such as any types of personalized information that allow the site to identify its visitors. All information collected indirectly will only be used to track the volume, type and configuration of traffic using this site. , to develop the design and layout and for other administrative and planning purposes and more generally to improve the service we offer you. You have the option to refuse the presence of cookies on your computer via the axeptio module provided for this purpose.')}
          />
        </Container>
      </Content>
    </Block>
  );
};

LegalContainer.propTypes = {

};

LegalContainer.defaultProps = {

};

export default LegalContainer;
