import React from 'react';
import PropTypes from 'prop-types';
import {
  Block,
  Title,
  Info
} from './StyledElements';

const LegalTxt = ({ titleLegal, infoLegal }) => {
  return (
    <Block>
      <Title>{titleLegal}</Title>
      <Info>{infoLegal}</Info>
    </Block>
  )
}

LegalTxt.prototype = {
  titleLegal: PropTypes.string,
  infoLegal: PropTypes.string,
};
  
LegalTxt.defaultProps = {
};

export default LegalTxt;