import React from 'react';
import { WithIntl } from '../components/language/withIntl';
import LegalContainer from '../Containers/LegalContainer';
import Layout from '../components/Layout';
import HeaderHtml from '../components/HeaderHtml';
import i18n, { localize } from '../components/language/config';

const Legal = function () {
  const { language } = i18n;

  return (
    <Layout>
      <HeaderHtml
        language={language}
        title={localize('Legal - Evolt')}
        description={localize('Legal informations - Create branded polls, surveys and user tests with the évolt feedback tool.')}
      />
      <LegalContainer />
    </Layout>
  );
};

export default WithIntl()(Legal);
